.signup-container {
    width: 100%;
    height: 902px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
}

.mainContainerheight {
    width: 100%;
    height: 902px;
}

.signup-heading {
    font-size: 25px;
    font-weight: 600;
}

.form-style {
    width: 80%;
    margin: 22% auto;
    margin-bottom: 0% !important;
}

.form-style .ant-col-24.ant-form-item-label,
:where(.css-dev-only-do-not-override-12upa3x).ant-col-xl-24.ant-form-item-label {
    font-weight: 500;
}

.sign-up-button {
    color: white;
    font-size: 18px;
    background-color: rgb(66, 142, 199);
    width: 100%;
    margin: 15px auto;
    margin-bottom: 20px !important;
    height: 45px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.form-style .ant-input {
    height: 42px;
    border-radius: 6px;
}

.signup-footer-container {
    width: 80%;
    margin: 0px auto;
    margin-top: 40px;
}

.horizontal-ruler-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 5px;
    text-align: center;
}

.horizonatal-ruler {
    height: 1px;
    width: 35%;
    background-color: black;
}

.social-login-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-top: 20px;
    gap: 40px;
}

.social-login-containerr {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-top: 20px;
    gap: 40px;
    width:100%
}

.social-login-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.socail_img{
    width: 40px;
    height: 40px;
    display: none;
}

.social-login-text {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    font-size: 16px;
    align-items: center;
    margin-top: 8px;
}

.login-link {
    color: rgb(66, 142, 199);
    font-weight: 600;
}


.already_accoiunt {
    margin-bottom: 0 !important;
}

.signInWith {
    font-size: 16px;
    margin: 0;
}

.signHide{
    display: none
}

.otp-form-contianer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20% auto;
    margin-bottom: 0% !important;
}

.sign-otp-up-button {
    color: white;
    font-size: 18px;
    background-color: rgb(66, 142, 199);
    width: 100%;
    margin: 15px auto;
    margin-bottom: 1px !important;
    height: 45px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.sign-otp-up-button-disbale-btn {
    cursor: not-allowed;
}


.signup-otp-heading {
    font-size: 25px;
    font-weight: 600;
    margin: 5px 0px;
}

.verify-para {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 18px;
}

.otp-style {
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.input-style {
    height: 45px;
    width: 42px !important;
    border-radius: 8px;
    border: 1px solid gainsboro;
}

.otp-notify-para {
    color: gray;
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0;
    display: flex;
}

.ant-statistic-title{
  margin-bottom : 0px !important
}

.ant-statistic-content {
    padding-left: 3px;
}

.otp-again {
    cursor: pointer;
    color: gray;
    font-size: 16px;
    font-weight: 600;
}

span.ant-statistic-content-value {
    color: gray !important;
    font-size: 14px !important;
}

.check-style {
    display: flex;
    gap: 3px;
}

.checkbox-text {
    color: black;
    font-weight: 300;
    text-align: left;
    word-spacing: 2px;
    display: inline;
    font-size: 14px
}

.checkbox-text span {
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

.horizontal-otp-ruler-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
}

.horizonatal-otp-ruler {
    height: 1px;
    width: 38%;
    background-color: black;
}

.social-otp-login-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-top: 20px;
    gap: 20px;
}

.social-otp-login-item {
    cursor: pointer;
}

.social-otp-login-text {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    font-size: 16px;
    align-items: center;
    font-weight: 600;
    color: gray;
}

.login-otp-link {
    color: rgb(66, 142, 199);
    font-weight: 600;
}

.ant-btn:hover, .ant-btn:focus {
    color: white !important;
    border-color: none !important;
    background-color: rgb(66, 142, 199) !important;
}

.error-password{
    color: red;
    margin: 0;
}

.ant-form-item.removeInvalidMargin{
    margin-bottom: 0px !important;
}

.checkboxClass{
    margin-bottom: 0;
}

@media(max-width:1330px) {
    .otp-form-contianer {
        margin: 30% auto;
    }
    .signInWith{
        font-size: 13px;
    }
}

@media(max-width:1132px) {
    .signInWith {
        font-size: 12px;
    }

    .form-style {
        margin: 35% auto;
    }
}

@media(max-width:991px) {
    .form-style {
        margin: 40% auto;
    }

    .verify-para {
        font-size: 16px;
    }

    .checkbox-text {
        font-size: 15px;
    }
}

@media(max-width:768px) {
    .form-style {
        margin: 10% auto;
    }

    .signup-container {
        height: 750px;
    }

    .otp-form-contianer {
        margin: 10% auto;
    }

    .verify-para {
        font-size: 18px;
    }

}

@media (max-width: 575px) {
    .form-style {
        width: 90%;
        margin: 10% auto;
    }

    .otp-form-contianer {
        width: 85%;
    }
}

a:hover {
    color: rgb(66, 142, 199);
}

.input-style:focus {
    border: 1px solid  rgb(66, 142, 199);
}

.ant-select-selector {
    height: 42px !important;
    border-radius: 5px !important;
}

.selectItem{
    margin-bottom: 25px;
}

/* .nsm7Bb-HzV7m-LgbsSe.jVeSEe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
    border: none !important;
    background: #fff !important;

} */
.nsm7Bb-HzV7m-LgbsSe{
    border: 1px solid #fff !important;
}