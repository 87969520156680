.profile-page-wrapper {
    min-height: 100vh;
    width: 90%;
    margin: auto;
    background-color: #ffff;
}

.profile-recent-button {
    border-radius: 10px 0px 0px 10px !important;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.10);
    width: 50%;
    height: 50px !important;

}

.profile-past-button {
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.10);
    width: 50%;
    height: 50px !important;

}

.profile-page-text {
    margin: 6px auto;
    color: #353535;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Medicine-page-text {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.searchBoxDoc_profile {
    width: 100%;
    height: 48px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.03);
    padding: 12px;
}

.searchInput_profile {
    outline: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.space-align-block {
    display: flex;
    align-items: center;
    /* 125% */
}

.active-menu {
    color: #498ECC;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    /* 125% */
}

.recent-card {
    /* width: 741px;
    height: 922px; */
    border-radius: 8px;
    border-radius: 10px;
    margin-top: 10px;
    /* background: #F4F4F4; */
    box-shadow: 9px 0px 20px 0px rgba(0, 0, 0, 0.03);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    background-color: inherit;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span {
    color: #498ECC !important;
}

.small-health-id {
    color: #353535;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.profileCard {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background-color: #F4F4F4;
    box-shadow: 9px 0px 20px 0px rgba(0, 0, 0, 0.03);
    margin-right: 15px;
    height: 200px;
    padding-top: 10px;
}

.menu-item-list-sibar {
    background-color: #F4F4F4;
    margin-right: 15px;
    padding-top: 30px;
}

.small-health-edit-profile {
    color: #353535;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    cursor: pointer;
}

.text-label-css-input {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}

.text-input-css {
    border-radius: 12px;
    border: 0.5px solid #B8B8D2;
    background: #FFF;
    padding: 14px;
}

.toggleButton-css {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 12px !important;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.10);
}

.toggleButton-css-first {
    border-radius: 12px 0 0 12px !important;
    border: none !important;
    text-align: center;
    width: 50%;
    height: 50px;
    padding: 12px;
}

.toggleButton-css-second {
    border-radius: 0px 10px 10px 0px !important;
    border: none !important;
    text-align: center;
    width: 50%;
    height: 50px;
    padding: 12px;
}

.submit-btn-form {
    border-radius: 29.5px !important;
    background: #498ECC !important;
    width: 100%;
    height: 50px !important;
    color: #FFFFFF;
    border:none
}

.menu-icon-width-size {
    width: 40px;
    height: 40px;
}

.Settingheading {
    color: #353535;
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    /* 100% */
    margin-bottom: 40px;
    margin-top: 40px;
}

.content-layout-style-props {
    /* max-width: 500px ;
    min-width: 500px ;
    width: 500px ;
    position: absolute ;
    z-index: 5; */
}

@media(max-width:575px) {
    .lay-out-sider-css {
        /* max-width: 500px ;
        min-width: 500px ;
        width: 500px ;
    position: absolute;
    z-index: 10; */

    }
}

.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 4px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: #001529;
    font-size: 23px;
    line-height: 42px;
    text-align: center;
    background: #F4F4F4;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    transition: background 0.3s ease;
}

.mrp-text {
    color: #353535;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mrp-amount {
    color: #353535;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.qun-text {
    /* color: rgba(53, 53, 53, 0.60); */

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sidebartext {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
}

.list-items-text-fields {
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.view-prescription-text {
    color: rgb(26, 77, 247);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.list-items-containers {
    border-radius: 8px;
    border: 1px solid rgba(84, 145, 204, 0.20);
    background: #FFF;
    box-shadow: 4px 4px 8px 0px rgba(140, 140, 140, 0.10);
    margin-top: 12px;
    height: 96px;
    padding: 11px;
    cursor: pointer;
}

.past-list-items-containers {
    border-radius: 8px;
    border: 1px solid rgba(84, 145, 204, 0.20);
    background: #f2f2f2;
    box-shadow: 4px 4px 8px 0px rgba(140, 140, 140, 0.10);
    margin-top: 10px;
    height: auto;
    padding: 10px !important;
}

.itemContainerWiidths {
    width: 90%;
    margin: 0 auto;
}

.main-heading {
    color: #353535 !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 150 !important;
    line-height: normal;
    margin-right: 100px;
}

.appointment {
    color:#353535;
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    /* 100% */
    margin-bottom: 100;
    margin-top: 40px;
}

.phone-consultation-wrapper{
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 16px;
}

.appoinments-details-img{
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.appointments-button-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    height:100%
}

.model-title{
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #001529 !important;
}

.modal--header-container{
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    column-gap:16px;
}

.model-description{
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #272727 !important;
    max-width: 280px;
}

.warning-icons{
    font-size: 28px !important;
}

.confirmation_modal{
    padding: 24px !important;
}

.MuiDialog-paperWidthSm{
    padding: 24px !important;
}

.MuiDialogTitle-root{
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiDialogContentText-root{
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiDialogActions-root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.locationImg{
     height: 25px;
     width: 17px   
}

.calenderImg{
     height: 25px;
     width: 25px;
     gap: 100px ;
}

.clockImg{
    margin-top: 20px;
}

.clock-img{
    height: 25px;
    width: 25px;
    gap: 100px   
}

.previousAppointmentList_container{
    max-height: 400px;
    overflow-y:auto
}
