.doctorList {
    /* display: flex; */
    width: 90%;
    margin: 0 auto;
    /* align-items: center; */
    /* justify-content: space-between; */
    margin-bottom: 20px;
    /* flex-wrap: wrap; */

}

.doctorWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    border-bottom: 1px solid #EAEAEA;
    padding: 20px 0;
}

.doctor-left-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
}

.doctor-left-side-content {
    display: flex;
    flex-direction: column;
}

.doctorfileWrapper {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.searchContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70;
    left: -50% !important;
    width: 745px;
    overflow-y: auto;
    max-height: 500px;
    z-index: 999;
    background: #f4f4f4 !important;
    border-radius: 7px !important;
    opacity: .95;
    row-gap: 16px;
    /* padding: 12px !important; */
}

/* .setbackground{
    padding:12px !important
} */

.no_result_found{
    padding: 12px 8px !important;
}

.search-doctor-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    background: #fff;
    cursor: pointer;
    border-radius: 8px !important;

}

.doctor_search_profile_pic{
    width: 63px;
    height: 63px;
    border-radius: 50%;
}

.src_doctorname_container {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    min-width: 170px;
}

.search_doctorfullname {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #353535;
}

.search_doctorfullname_category {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #353535;
    margin-top: 8px !important;
    text-transform: capitalize !important;
}

.search_specialization{
    width: 200px;
}

.search_specialization_text{
    text-transform: capitalize;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.locationIcon {
    width: 20px;
    height: 20px;
}

.patient-cityname {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
}

.book__appointment__button {
    color: #FFF !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 100 !important;
    line-height: normal;
}


.doctorList-avatar {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    margin-right: 24px
}

.doctorExperienceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

}


.search__placholder {
    color: #282828;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    border-radius: 7px;
}

.doctorName {
    color: #353535;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    font-style: normal;
}

.doctorDescription {
    color: #353535;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    margin-top: 10px !important;
}

.doctorLanguageTitle {
    color: #353535;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    font-style: normal;

}

.city-area-container{
    position: absolute;
    display: flex;
    gap: 16px;
     flex-direction: column;
     max-height: 500px;
     overflow-y: auto;
    width: 100% !important;
    z-index: 999 !important;
    top:50px;
}

.doctorFees,
.doctor_specialisation {
    color: #353535;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 0 !important;
}

.bookAppointmentButton {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #498ECC !important;
    color: #ffff !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 30px !important;
    font-style: normal !important;
    height: 60px !important;
    width: 268px !important;
    cursor: pointer;
    transition: background-color 0.3s ease
}

.bookAppointmentButton:hover {
    background-color: #1976d2 !important;
}

@media(max-width:500px) {

    .bookAppointmentButton {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background: #498ECC !important;
        color: #ffff !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        border-radius: 30px !important;
        font-style: normal !important;
        height: 60px !important;
        width: 268px !important;
        cursor: pointer;
        margin-top: 32px !important;
        margin-left: 32px !important;
    }
}

.continueButton {
    background: #498ECC !important;
    color: #ffff !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 30px !important;
    font-style: normal !important;
    width: 100%;
    min-height: 50px;
    max-width: 268px;
    height: 60px;

}

.continueButton:hover {
    background-color: #1976d2 !important;
}

.searchInput {
    background: #f4f4f4;
    border-radius: 7px;
    height: 48px;
    width: 100%;
}


.evening-time-slot-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 28px;
    max-width: 650px;
}

.online_consultant_title {
    /* color:#498ECC ;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px; 
    font-style: normal;
    margin-bottom: 24px;   */


    color: #353535;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 24px 0;
}

.doctor-right-container {
    min-width: 268px;
}

.doctor_consultant_card {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
    cursor: pointer;
}

.textConsultation {
    border: 1px solid red !important
}

.doctor_consultant_wrapper {
    border-radius: 8px;
    background: #FFF;
    cursor: pointer;

}

.doctor_consultant_title {
    color: #498ecc !important;
    font-size: 21px !important;
    font-weight: 600 !important;
    line-height: 20px;
    font-style: normal;
    margin-bottom: 24px !important;
    margin-top: 24px !important;

}

.doctor_consultant_description {
    color: #353535 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.doctor_consultant_fees {
    color: #498ECC !important;
    font-size: 21px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    margin-top: 16px;
}

.doctor_consultant_card_icon {
    height: 50px !important;
}

.doctor-language {
    font-size: 13px !important;
    font-weight: 400 !important;
}

.moreAboutDoctor_consultant_heading {
    color: #353535 !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 24px 0;
    margin-top: 24px;

}

.moreAbout_title {
    color: #498ECC !important;
    font-size: 21px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.consultant_survices,
.consultant_language {
    text-transform: capitalize !important;
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 8px;
}

.card_certification {
    border-radius: 8px;
    background: #FCFCFC;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.14);
    margin-top: 50px;
}


.certification_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card_certification_img {
    width: 97px;
    height: 97px;
}

.locationImgPatient {
    width: 28px;
    height: 28px;
}


.search_text_style{
    font-size: 14px;
    font-weight: 400;
}
.ant-tabs-top>.ant-tabs {
    margin-bottom: 32px !important;
}

.online_consultation_img {
    width: 60px !important;
    height: 60px !important;
    margin-bottom: 24px !important;
}


.language_container {
    display: flex !important;
    justify-content: flex-start !important;
    gap: 16px !important;
}

.language_icons {
    width: 32px;
    height: 32px;
}

.service_offer_icons {
    width: 40px;
    height: 40px;

}




.select_calender {
    background: #498ECC !important;
    color: #fff !important;
    border-radius: 30px !important;
    width: 268px !important;
    height: 50px !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
}

.customized input {
    font-size: 18px !important;
    font-weight: 600;
    color: #fff !important;
}

.time-slot-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-style: normal;
}


.doctor_profile_container {
    display: flex;
    align-items: center;
}

.morning-time-slot-container {
    /* display: flex;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap; */
    max-width: 80px;
}

.time-slot-img {
    margin-right: 24px;
}

.ant-segmented {
    padding: 0 !important;
    font-size: 18px !important;
}

.ant-segmented .ant-segmented-group {
    position: relative;
    display: flex;
    gap: 24px;
    align-items: stretch;
    justify-items: flex-start;
    width: 100%;
    border-radius: 8px;
    background: #fff;

    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);


}

.ant-segmented-item {
    background: #F7F7F7;
    padding: 8px 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;

}

.ant-segmented-item-selected {
    background-color: #498ECC !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
    color: #fff !important;

}

.consultation-text-label {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-transform: capitalize;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
}

.MuiToggleButtonGroup-root {
    border-radius: 4px;
    width: 100% !important;
    max-width: 800px !important;
    display: flex;
    justify-content: center;
}

.MuiToggleButton-root.Mui-selected {
    border-radius: 29.5px;
    background: #498ECC !important;
    color: #FFF !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 100 !important;
    line-height: normal;
    width: 50% !important;
    text-transform: capitalize !important;

}


.MuiToggleButton-root {
    border: 1px solid #498ECC !important;
    background-color: #fff;
    border-radius: 29.5px !important;
    color: #B0AAAA !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 100 !important;
    line-height: normal;
    width: 50% !important;
    text-transform: capitalize !important;

}

@media(max-width:600px) {
    .MuiToggleButton-root {
        border: 1px solid #498ECC !important;
        background-color: #fff;
        border-radius: 29.5px !important;
        color: #B0AAAA !important;
        text-align: center;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
        width: 150px !important;
        text-transform: capitalize !important;

    }

    .MuiToggleButton-root.Mui-selected {
        border-radius: 29.5px;
        background: #498ECC !important;
        color: #FFF !important;
        text-align: center;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
        width: 150px !important;
        text-transform: capitalize !important;

    }


}


.rightArrowIcon {
    max-width: 12px;
    width: 10px;
    margin-left: 12px;
}

/* @media(max-width:800px) {
    .MuiToggleButton-root{
        border: 1px solid #498ECC !important;
        background-color: #fff;
        border-radius: 29.5px !important;
        color:#B0AAAA !important;
        text-align: center;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 100 !important;
    line-height: normal;
    width:150px !important;
    text-transform: capitalize;
    
    }

    .MuiToggleButton-root.Mui-selected{
        border-radius: 29.5px;
        background: #498ECC !important;
        color: #FFF !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    width:150px !important;
    text-transform: capitalize;
    }
} */


.toggleInactive1 {
    margin-left: -40px !important;
}

.toggleInactive2 {
    margin-right: -40px !important;
}


.appoitment-details-icons {
    width: 25px;
    height: 25px;
}

.rs200 {
    border: 2px solid #498ECC
}

.moringActiveTimeSlot {
    background-color: #FBBB2C !important;
}

.disableTimeSlot {
    background-color: #808080 !important;
}

.booking-detail-card {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
    margin-bottom: 24px !important;
}

/* .ant-picker-input > input{
color: #FFF;
text-align: center;
font-family: "Encode Sans", sans-serif !important;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
} */

/* 
.ant-segmented-item-label{
font-family: "Encode Sans", sans-serif !important;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 20px;
} */


.text-fee-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
}

.time_slot_button {
    max-width: 161px;
    width: 100%;
    height: 55px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FBBB2C;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}


.appointment_details_basic_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 18px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
    max-width: 420px;
    width: 100%;
    height: auto;
}


.payment_method__container {
    max-width: 456px;
    width: 100%;
    height: auto;
    padding: 24px;
    padding-top: 0;
}

.appointment_details_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: fit-content;
}

.appointment-details-row-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 18px;
}

.appointment-details-label-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.text-label {
    color: #498ECC;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    margin-bottom: 8px !important;
}

.text-label-value {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 16px;
}

.appointment_checkout_offers {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
    max-width: 358px;
    width: 100%;
}

.time_slot_container {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    flex-wrap: wrap !important;
    gap: 24px;
    margin: 32px 0;
}

.time_slot_label {
    border-radius: 8px;
    background: #F7F7F7;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    width: 161px !important;
    height: 55px;
    margin-right: 12px;
    flex-shrink: 0;
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.time_slot_label_disable {
    border-radius: 8px;
    background: #bdbdbd    ;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    width: 161px !important;
    height: 55px;
    margin-right: 12px;
    flex-shrink: 0;
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-details-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    max-width: 500px;
}

.checkout-details-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    max-width: 500px;
}

.payment-details-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.checkout-details-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment-details-label {
    color: #353535;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    margin-bottom: 12px;
}

.radio-button-label {
    color: #353535;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.checkout-details-label {
    color: #353535;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    margin-bottom: 12px;
}

.payment-details-consultation-fees .payment-details-consultation-fees-amount {
    color: #353535;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    margin-bottom: 10px;
}

.checkout-details-consultation-fees {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
}


.checkout-offers-cuppon {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
}

.payment-details-total-amount {
    color: #498ECC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.payment-details-total-amount-fees {
    color: #498ECC;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    /* 125% */
}

.payment-details-faqa {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    margin-top: 32px;
}

.payment-details-checkout-button {
    width: 268px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #498ECC;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
}

.payment-details-FAQs-text {
    color: #498ECC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}

.appointment-details {
    color: #353535;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 71.429% */
    margin: 16px 0;
}

.credit-debit-card-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
    width: 488px;
    height: 261px;
    flex-shrink: 0;
}

.payment-wallets-title {
    color: #353535;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 4px 0 24px 0;
}

.logo-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.payment-icon {
    max-width: 42px;
    width: 100%;
    height: auto;
    margin-right: 32px;
}

.bhim-payment-icon {
    max-width: 90px;
    width: 100%;
    height: auto;

}

.booking-successful-message {
    color: #498ECC;
    font-size: 28px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    margin: 32px 24px 16px 24px;
}

.booking-appointment {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.successful-logo {
    max-width: 400;
    width: 100%;
    height: auto;
    max-height: 450px;


}

@media(max-width:500px) {
    .successful-logo {
        max-width: 200;
        width: 100%;
        height: auto;
    }

}

.patient-appoitment-title {
    color: #498ECC;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px !important;
}

.patient-appointment-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}

.appointment-subtitle {
    color: #353535;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px !important;
}

.appointment-text {
    color: #353535;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
}

.go-to-appointment {

    width: 268px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #498ECC;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.booking-details-card {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
}

.offers-label-text-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.offer-coupon-code-label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px
}

.offer-section-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
}

.language-know-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}

.ant-btn[disabled]{
    background: #bdbdbd !important;
}

.viewClinicDetails{
    color: #498ECC;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.hideClinicDetails{
    display: flex;
    width: 90%;
    justify-content: right;
    color: #498ECC;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.doctor_experience{
    font-size: 13px !important;
    font-weight:400 !important ;
}

.doc_review{
    font-size: 13px !important;
    font-weight: 600;
}

.doc_profile_pic{
    width: 110px !important;
    height: 110px !important;
    border-radius: 50% !important;
    object-fit: contain;
}