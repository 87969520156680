.signIn-container {
  width: 100%;
  height: 902px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.signIn-heading {
  font-size: 25px;
  font-weight: 600;
  margin: 0px 0;
}

.signIn-text {
  color: gray;
  line-height: 30px;
  font-weight: 500;
  margin: 2px 0;
  margin-bottom: 15px !important;
  font-size: 16px;
}

.signIn-form-style {
  width: 80%;
  margin: 20% auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 0% !important;
}

.signIn-label {
  font-weight: 500;
  font-size: 16px;
  margin: 5px 0;
}

.select-role-style {
  height: auto;
  padding: 10px;
  border-radius: 5px;
}

.select-arrow-icon {
  pointer-events: none;
  color: black;
}

.ant-select:active .ant-select-arrow {
  transform: rotate(180deg);
}

.genrate-otp-button {
  color: white;
  font-size: 16px;
  background-color: rgb(66, 142, 199);
  width: 300px;
  margin: 15px 0;
  margin-bottom: 1px !important;
  height: 45px;
  border-radius: 20px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.logIn-bottom-text {
  width: 80%;
  margin: 8% auto;
  margin-bottom: 0% !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.logIn-bottom-text p {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
}

.sign-up-navigation {
  cursor: pointer;
  color:  rgb(66, 142, 199);
  font-weight: 600 !important;
}

.signIn-form-style .ant-input {
  height: 45px;
  border-radius: 10px;
}

.signIn-form-style .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 45px;
  padding: 2px 11px;
  border-radius: 10px;
}


.horizonatal-login-otp-ruler {
  height: 1px;
  width: 100%;
  margin: 30px 0;
  background-color: gainsboro;
}

.login-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-otp-notify-para {
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin: 15px 0;
  display: flex;
  align-items: center;
}


.otp-timer {
  color: gray;
  font-size: 14px;
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
}

.login-otp-again {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.login-button {
  color: white;
  font-size: 14px;
  background-color: rgb(66, 142, 199);
  width: 160px;
  margin: 0px 0;
  margin-bottom: 1px !important;
  height: 45px;
  border-radius: 20px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.logIn-otp-bottom-text {
  width: 80%;
  margin: 8% auto;
  margin-bottom: 0% !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.logIn-otp-bottom-text p {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
}

.ant-select-selection-item {
  align-self: center;
  font-weight: 500;
  font-size: 15px;
}

.form-email-text {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: none !important;
  background-color: #40a9ff !important;
}

@media(max-width:1200px) {
  .signIn-form-style {
    width: 80%;
    margin: 32% auto;
  }
}

@media(max-width:991px) {
  .signIn-form-style {
    width: 80%;
    margin: 32% auto;
  }

  .logIn-bottom-text{
    margin: 20% auto;
  }
}

@media(max-width:768px) {
  .signIn-form-style {
    width: 80%;
    margin: 16% auto;
  }

  .logIn-bottom-text {
    width: 80%;
    margin: 8% auto;
    margin-bottom: 5% !important;
  }

  .signIn-container {
    height: 720px;
  }
}

@media (max-width: 475px) {

  .login-footer-container {
    flex-direction: column;
  }

  .signIn-form-style {
    width: 90%;
  }

  .logIn-otp-bottom-text {
    width: 80%;
    margin: 10% auto;
  }
}